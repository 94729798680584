
<template>
  <div class="container">
    <!--<div class="take-input">
     <input type="text" placeholder="Write any text" />
    <a href="javascript:void(0);">Enter Text</a>
  </div>-->
    <div class="animate seven" style="letter-spacing: 10px">
      <span style="font-normal: bold; font-size: 50px; margin: 2px">构</span>
      <span style="font-normal: bold; font-size: 50px; margin: 2px">建 </span>
      <span style="font-weight: bold; font-size: 54px; margin: 2px">一</span>
      <span style="font-weight: bold; font-size: 54px; margin: 2px">体</span>
      <span style="font-weight: bold; font-size: 54px; margin: 2px">化</span>
      <span style="font-weight: bold; font-size: 54px; margin: 2px">、</span>
      <span style="font-weight: bold; font-size: 54px; margin: 2px">智</span>
      <span style="font-weight: bold; font-size: 54px; margin: 2px">能</span>
      <span style="font-weight: bold; font-size: 54px; margin: 2px">化</span>
      <span style="font-weight: normal; font-size: 50px; margin: 2px">数</span>
      <span style="font-weight: normal; font-size: 50px; margin: 2px">据</span>
      <span style="font-weight: normal; font-size: 50px; margin: 2px">平</span>
      <span style="font-weight: normal; font-size: 50px; margin: 2px">台</span>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
  methods: {
    /* This code is not required for the animation. This is only needed for the repeatation */
    startAnim() {
      let classes = $(this).parent().attr("class");
      $(this).parent().attr("class", "animate");
      var indicator = $(this);
      setTimeout(function () {
        $(indicator).parent().addClass(classes);
      }, 20);
    },
  },
};
</script>

<style scoped="scoped">
@import url("https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i");

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #999999;
  word-wrap: break-word;
}

p {
  margin: 0 0 10px;
}

ul {
  list-style: none;
}

.container {
  width: 100%;
  width: 100vw;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
}

/*.take-input {
  margin: 50px 0 0;
}

.take-input input {
  width: 400px;
  height: 35px;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid #ececec;
  margin: 0 15px 0 0;
  font-size: 15px;
}*/

a,
a:link,
a:visited {
  text-decoration: none;
  padding: 9px 15px;
  border: 1px solid #ececec;
  border-radius: 5px;
  color: gray;
}

.animate {
	width: 100%;
  font-size: 50px;
}

.animate span {
  display: inline-block;
}

a.repeat {
  display: inline-block;
  font-size: 12px;
  text-transform: none;
  text-decoration: none;
  color: orange;
  padding: 5px 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);

  margin: 0 0 0 50px;
  border-radius: 3px;
  position: relative;
  bottom: 15px;
}

a.repeat:hover {
  background: rgba(0, 0, 0, 0.7);
  color: white;
}

.animate span:nth-of-type(2) {
  animation-delay: 0.05s;
}
.animate span:nth-of-type(3) {
  animation-delay: 0.1s;
}
.animate span:nth-of-type(4) {
  animation-delay: 0.15s;
}
.animate span:nth-of-type(5) {
  animation-delay: 0.2s;
}
.animate span:nth-of-type(6) {
  animation-delay: 0.25s;
}
.animate span:nth-of-type(7) {
  animation-delay: 0.3s;
}
.animate span:nth-of-type(8) {
  animation-delay: 0.35s;
}
.animate span:nth-of-type(9) {
  animation-delay: 0.4s;
}
.animate span:nth-of-type(10) {
  animation-delay: 0.45s;
}
.animate span:nth-of-type(11) {
  animation-delay: 0.5s;
}
.animate span:nth-of-type(12) {
  animation-delay: 0.55s;
}
.animate span:nth-of-type(13) {
  animation-delay: 0.6s;
}
.animate span:nth-of-type(14) {
  animation-delay: 0.65s;
}
.animate span:nth-of-type(15) {
  animation-delay: 0.7s;
}
.animate span:nth-of-type(16) {
  animation-delay: 0.75s;
}
.animate span:nth-of-type(17) {
  animation-delay: 0.8s;
}
.animate span:nth-of-type(18) {
  animation-delay: 0.85s;
}
.animate span:nth-of-type(19) {
  animation-delay: 0.9s;
}
.animate span:nth-of-type(20) {
  animation-delay: 0.95s;
}
.animate span:nth-of-type(21) {
  animation-delay: 1s;
}
.animate span:nth-of-type(22) {
  animation-delay: 1.05s;
}
.animate span:nth-of-type(23) {
  animation-delay: 1.1s;
}
.animate span:nth-of-type(24) {
  animation-delay: 1.15s;
}
.animate span:nth-of-type(25) {
  animation-delay: 1.2s;
}

/* Animation One */

.one span {
  color: #24a8e6;
  opacity: 0;
  transform: translate(-150px, -50px) rotate(-180deg) scale(3);
  animation: revolveScale 0.4s forwards;
}

@keyframes revolveScale {
  60% {
    transform: translate(20px, 20px) rotate(30deg) scale(0.3);
  }

  100% {
    transform: translate(0) rotate(0) scale(1);
    opacity: 1;
  }
}

/* Animation Two */

.two span {
  color: #a5cb21;
  opacity: 0;
  transform: translate(200px, -100px) scale(2);
  animation: ballDrop 0.3s forwards;
}

@keyframes ballDrop {
  60% {
    transform: translate(0, 20px) rotate(-180deg) scale(0.5);
  }

  100% {
    transform: translate(0) rotate(0deg) scale(1);
    opacity: 1;
  }
}

/* Animation Three */

.three span {
  color: #b10e81;
  opacity: 0;
  transform: translate(-300px, 0) scale(0);
  animation: sideSlide 0.5s forwards;
}

@keyframes sideSlide {
  60% {
    transform: translate(20px, 0) scale(1);
    color: #b10e81;
  }

  80% {
    transform: translate(20px, 0) scale(1);
    color: #b10e81;
  }

  99% {
    transform: translate(0) scale(1.2);
    color: #00f0ff;
  }

  100% {
    transform: translate(0) scale(1);
    opacity: 1;
    color: #b10e81;
  }
}

/* Animation Four */

.four span {
  color: #8d6a00;
  opacity: 0;
  transform: translate(0, -100px) rotate(360deg) scale(0);
  animation: revolveDrop 0.3s forwards;
}

@keyframes revolveDrop {
  30% {
    transform: translate(0, -50px) rotate(180deg) scale(1);
  }

  60% {
    transform: translate(0, 20px) scale(0.8) rotate(0deg);
  }

  100% {
    transform: translate(0) scale(1) rotate(0deg);
    opacity: 1;
  }
}

/* Animation Five */

.five span {
  color: #dd3f0f;
  opacity: 0;
  transform: translate(0, -100px) rotate(360deg) scale(0);
  animation: dropVanish 0.5s forwards;
}

@keyframes dropVanish {
  30% {
    transform: translate(0, -50px) rotate(180deg) scale(1);
  }

  50% {
    transform: translate(0, 20px) scale(0.8) rotate(0deg);
    opacity: 1;
  }

  80% {
    transform: translate(-100px, -100px) scale(1.5) rotate(-180deg);
    opacity: 0;
  }

  100% {
    transform: translate(0) scale(1) rotate(0deg);
    opacity: 1;
  }
}

/* Animation Six */

.six span {
  color: #ddb40f;
  opacity: 0;
  transform: rotate(-180deg) translate(150px, 0);
  animation: twister 0.5s forwards;
}

@keyframes twister {
  10% {
    opacity: 1;
  }
  100% {
    transform: rotate(0deg) translate(0);
    opacity: 1;
  }
}

/* Animation Seven */

.seven span {
  color: white;
  opacity: 0;
  transform: translate(-150px, 0) scale(0.3);
  animation: leftRight 0.5s forwards;
}

@keyframes leftRight {
  40% {
    transform: translate(50px, 0) scale(0.7);
    opacity: 1;
    color: #348c04;
  }

  60% {
    color: #0f40ba;
  }

  80% {
    transform: translate(0) scale(2);
    opacity: 0;
  }

  100% {
    transform: translate(0) scale(1);
    opacity: 1;
  }
}
</style>