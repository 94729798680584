<template>
	<!-- 禁止页面横向滚动 -->
	<div class="about" style="overflow-x: hidden;">
		<!-- 回到顶部 -->
		<goTop></goTop>
		<transition name="fade">
			<div class="max-box" v-show="show">
				<header>
					<!-- 上边框虚线 -->
					<div class="top"></div>
					<div class="center">
						<img src="@/assets/img/about/about-banner.png">
						<aboutText class="aboutText"></aboutText>
					</div>
					
					<!-- 下边框虚线 -->
					<div class="bottom"></div>
				</header>
				<main>
					<div class="top">
						<!-- 逗号图片 -->
						<div class="douhao">
							<img src="@/assets/img/about/douhao-icon.png">
						</div>
						<div class="section">
							<p>
								中正数服（杭州）数据有限公司（简称“中正数服”）创立于2020年5月，总部位于杭州市滨江区，隶属于“觉加控股”的下属子公司。公司80%以上为技术研发人员，其中主要创始人来自阿里巴巴、丁香园等互联网巨头。2021年6月，中正数服获得觉加控股的天使轮融资，正在往大数据行业的独角兽企业高歌猛进。
							</p>
							<p>
								中正数服秉承“技术提升业务能力，数据赋能实战价值”理念，以“构建一体化智能化数据平台”
								为发展战略，聚焦大数据、人工智能、物联感知等核心技术，研发创新方向涵盖大数据分析研判平台、智能态势感知引擎、一体化公共数据平台、数字驾驶舱等方面，在政务、城市、产业领域，
								为客户提供行业大数据产品及行业数据智能综合解决方案。
							</p>
						</div>
						<el-row :gutter="20">
							<el-col :span="12">
								<div class="grid-content bg-purple">
									<div class="box">
										<img src="@/assets/img/about/zl.png">
										<div class="details">
											<h1>
												战略
											</h1>
											<p>
												数链为基，数脑为用，
											</p>
											<p>
												构建一体化智能化数据平台。
											</p>
										</div>
									</div>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="grid-content bg-purple">
									<div class="box">
										<img src="@/assets/img/about/sm.png">
										<div class="details">
											<h1>
												使命
											</h1>
											<p>
												数据智能助力治理现代化，
											</p>
											<p>
												助力产业数字化。
											</p>
										</div>
									</div>
								</div>
							</el-col>
						</el-row>
				
					</div>
				
					<!-- 中间 -->
					<div class="center">
						<div class="title">
							<h2>知识产权</h2>
							<p>intellectual property</p>
						</div>
						<div class="main">
							<el-row type="flex" class="row-bg" justify="space-around">
								<el-col :span="7">
									<div class="grid-content bg-purple">
										<!-- 左侧展示图 -->
										<img v-for="(cqimg,index) in cq.left.imgurl" :src="cqimg" @click="lf(cqimg,index)">
										<!-- 磨品 -->
									 <!-- <div class="mopin">
											<div class="details">
												<img src="@/assets/img/about/mp.jpg" >
											</div>
										</div> -->
									</div>
								</el-col>
								<el-col :span="8">
									<div class="grid-content bg-purple-light">
										<div class="details clearfix">
											<div class="left-img">
												<img :src="cq.center.imgurl" v-show="isImgShow">
											</div>
											<div class="right-text">
												<p>{{cq.center.section}}</p>
											</div>
										</div>
									</div>
								</el-col>
								<el-col :span="7">
									<div class="grid-content bg-purple">
										<!-- 右侧展示图 -->
										<img v-for="(cqimg,index) in cq.right.imgurl" :src="cqimg" @click="rt(cqimg,index)">
										<!-- 右侧logo标志 -->
										<!-- <div class="logo">
											<img src="@/assets/img/about/logo-icon.jpg">
										</div> -->
									</div>
								</el-col>
							</el-row>
						</div>
						<!-- 知识产权左侧省略号图片 -->
						<div class="left-more">
							<img src="@/assets/img/more.png">
						</div>
					</div>
					
					<!-- 蓝线下划线 -->
					<div class="line">
						<!-- 蓝线 -->
						<div class="line-act" :style="{left: scroll_index+'%'}"></div>
						<!-- 灰色线 -->
						<div class="line-none"></div>
					</div>
					<!-- 社会荣誉 -->
					<div class="bottom">
						<div class="title">
							<h2>社会荣誉</h2>
							<p>Social Honour</p>
						</div>
						<div class="main">
							<el-row :gutter="20">
							  <el-col :span="8" v-for="ryitem in ry">
								  <div class="grid-content bg-purple">
									  <el-image
									     :src="ryitem" 
									     :preview-src-list="ry">
									   </el-image>
								  </div>
								  </el-col>
							</el-row>
						</div>
					</div>
				</main>
			</div>
		</transition>
	</div>
</template>

<script>
	// 关于我们bnaner文字
	import aboutText from '@/components/about-details.vue'
	// 回到顶部
	import goTop from '@/components/goTop.vue'
	export default {
		name: "About",
		components:{
			aboutText,
			goTop
		},
		data() {
			return {
				show:false,
				// 知识产权
				// 进度条
				scroll_index:10,
				// 知识产权图片是否显示
				isImgShow:true,
				cq:{
					left:{
						imgurl:[
							require("@/assets/img/about/cq-01.jpg"),
							require("@/assets/img/about/cq-02.jpg"),
							require("@/assets/img/about/cq-03.jpg"),
							require("@/assets/img/about/cq-04.jpg"),
							require("@/assets/img/about/cq-05.jpg"),
						]
					},
					center:{
						imgurl:require("@/assets/img/about/cq-03.jpg"),
						section:`中正数服主要创始人经过多年的技术积累，在大数据算 法研究方面取得了一些成就， 并已成功将这些技术专利导 入公司，作为公司的无形资产，也为公司的产品研发、
													客户服务提供强有力的技术支撑。`
					},
					right:{
						imgurl:[
							require("@/assets/img/about/cq-06.jpg"),
							require("@/assets/img/about/cq-07.jpg"),
							require("@/assets/img/about/cq-08.jpg"),
							require("@/assets/img/about/cq-09.jpg"),
							require("@/assets/img/about/cq-10.jpg"),
						]
					}
				},
				// 社会荣誉
				ry:[
							require("@/assets/img/about/ry-01.jpg"),
							require("@/assets/img/about/ry-03.jpg"),
							require("@/assets/img/about/ry-02.jpg"),
							// require("@/assets/img/about/ry-01.jpg"),
						],
			};
		},
		methods: {
			// 知识产权左侧图片展示
			lf(img,index){
				this.cq.center.imgurl=img;
				console.log(index)
				this.scroll_index=index*10;
				this.isImgShow=false;
				var timer=setTimeout(()=>{
					this.isImgShow=true;
					clearTimeout(timer)
				},200)
			},
			// 知识产权右侧图片切换
			rt(img,index){
				this.cq.center.imgurl=img;
				console.log(index)
				this.scroll_index=(index+5)*10;
				this.isImgShow=false;
				var timer=setTimeout(()=>{
					this.isImgShow=true;
					clearTimeout(timer)
				},200)
			}
		},
		mounted(){
			this.show=true;
		}
	};
</script>

<style lang="scss" scoped>
	.fade-enter-active, .fade-leave-active {
	    transition: opacity 2s
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
	    opacity: 0
	}
	
	
	.about {
		// 顶部banner部分
		header {
			// 图片顶部虚线
			position: relative;

			.top {
				height: 0px;
				border-top: 1px dashed dimgray;
				box-sizing: border-box;
			}

			// 中间Banner部分
			.center {
				position: relative;
				img {
					width: 100%;
					z-index: 10 !important;
				}
				.aboutText{
					position: absolute;
					z-index: 100;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
				}
			}

			// 图片底部虚线
			.bottom {
				width: 100%;
				border-top: 1px dashed dimgray;
				position: absolute;
				top: 495px;
				z-index: 100 !important;
			}
		}

		// 中间部分
		main {
			position: relative;
			.top {
				width: 1440px;
				height: 800px;
				background: #FFFFFF;
				box-shadow: 0px 5px 18px 2px rgba(0, 0, 0, 0.1);
				border-radius: 10px;
				margin: 0 auto;
				margin-top: -100px;
				z-index: 1000 !important;
				.douhao{
					width: 82px;
					height: 46px;
					img{
						width: 72px;
						height: 46px;
					}
				}

				img {
					width: 112px;
					height: 46px;
					margin-left: 162px;
				}

				// 段落
				.section {
					width: 1175px;
					margin: 73px auto 0px;

					p {
						font-size: 20px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #333333;
						line-height: 48px;
						text-indent: 2em;
					}
				}

				.el-row {
					margin: 59px auto 0px;
					width: 1140px;

					.el-col {
						width: 550px;
						height: 280px;
					}

					.el-col:first-child {
						margin: 0px 40px 0px -20px;
					}

					.box {
						position: relative;

						img {
							width: 550px;
							height: 280px;
						}

						.details {
							width: 292px;
							position: absolute;
							top: 40%;
							left: 80%;
							transform: translate(-40%, -40%);

							h1 {
								height: 34px;
								font-size: 36px;
								font-family: Source Han Sans CN;
								font-weight: 500;
								color: #FFFFFF;
								padding: 0px;
								text-align: center;
								margin-bottom: 29px;
							}

							p {
								font-size: 22px;
								padding: 5px 0px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #FFFFFF;
								text-align: center;
								text-transform: uppercase;
							}
						}
					}
				}
			}

			// 中间
			.center {
				margin-top: 123px;
				text-align: center;

				.title {
					position: relative;
					height: 63px;

					h2 {
						height: 35px;
						font-size: 36px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #333333;
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						z-index: 100;
					}

					p {
						height: 28px;
						font-size: 36px;
						font-family: Verdana;
						font-weight: 400;
						color: #F3F3F3;
						position: absolute;
						top: 60%;
						left: 50%;
						transform: translateX(-50%);
						text-transform: uppercase;
					}
				}

				.main {
					max-width: 1440px;
					// margin-top: 82px;
					// margin-bottom: 50px;
					margin: 82px auto 50px;
					img{
						cursor: pointer;
					}
					.el-col:first-child {
						position: relative;
						img:first-child {
							width: 111px;
							height: 154px;
							border-radius: 5px;
							position: absolute;
							top: 0px;
							right: 140px;
						}

						img:nth-child(2) {
							width: 82px;
							height: 113px;
							border-radius: 5px;
							position: absolute;
							top: 94px;
							left: 0px;
							
						}

						img:nth-child(3) {
							width: 111px;
							height: 154px;
							border-radius: 5px;
							position: absolute;
							top: 264px;
							left: 19px;
						}

						img:nth-child(4){
							width: 82px;
							height: 113px;
							border-radius: 5px;
							position: absolute;
							top: 386px;
							right: 113px;
						}
						
						img:nth-child(5){
							width: 82px;
							height: 113px;
							border-radius: 5px;
							position: absolute;
							top: 40%;
							right: 10%;
						}

					}

					.el-col:nth-child(2) {
						width: 580px;
						height: 526px;
						background: #FFFFFF;
						border: 3px solid #E5E5E5;
						border-radius: 10px;
						.details{
							clear: both;
							box-sizing: border-box;
							.left-img{
								float: left;
								width: 338px;
								height: 520px;
								background: #FDF7F1;
								border-radius: 10px;
								position: relative;
								img{
									width: 317px;
									height: 427px;
									border-radius: 10px;
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%,-50%);
									cursor: default;
								}
							}
							.right-text{
								float: left;
								position: relative;
								text-align:center;
								width: 230px;
								height: 520px;
								p{
									width: 179px;
									height: 303px;
									font-size: 16px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #333333;
									line-height: 36px;
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%,-50%);
								}
							}
						}
					}

					.el-col:last-child {
						position: relative;
						img:first-child {
							width: 82px;
							height: 113px;
							border-radius: 5px;
							position: absolute;
							top: 26px;
							left: 84px;
						}

						img:nth-child(2) {
							width: 111px;
							height: 154px;
							border-radius: 5px;
							position: absolute;
							top: 77px;
							left: 250px;
						}

						img:nth-child(3) {
							width: 82px;
							height: 113px;
							border-radius: 5px;
							position: absolute;
							top: 307px;
							left: 313px;
						}

						img:nth-child(4){
							width: 111px;
							height: 154px;
							border-radius: 5px;
							position: absolute;
							bottom: 1px;
							left: 118px;
						}
						img:nth-child(5){
							width: 82px;
							height: 113px;
							border-radius: 5px;
							position: absolute;
							top: 40%;
							left: 10%;
						}
					}
				}
				
				
			}
			
			.center{
				position: relative;
				// 左侧省略号图片
				.left-more{
					width: 177px;
					height: 409px;
					position: absolute;
					top: 0px;
					img{
						width: 177px;
						height: 409px;
					}
				}
			}
			
			
			// 蓝线
			.line{
				width: 200px;
				margin: 0 auto;
				position: relative;
				margin-bottom: 147px;
				.line-act{
					width: 20px;
					height: 3px;
					background: #09419B;
					position: absolute;
					top: 0px;
					z-index: 100;
				}
				.line-none{
					width: 200px;
					height: 3px;
					background: #E5E5E5;
					position: absolute;
					top: 0px;
				}
			}
			
			
			
			
			// 底部
			.bottom{
				.title {
					position: relative;
					height: 63px;
				
					h2 {
						height: 35px;
						font-size: 36px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #333333;
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						z-index: 100;
					}
				
					p {
						height: 28px;
						font-size: 36px;
						font-family: Verdana;
						font-weight: 400;
						color: #F3F3F3;
						position: absolute;
						top: 60%;
						left: 50%;
						transform: translateX(-50%);
						text-transform: uppercase;
					}
				}
				.main{
					max-width: 1440px;
					margin: 77px auto 163px;
					.el-row{
						.el-col{
							text-align: center;
							.el-image{
								width: 331px;
								height: 470px;
							}
						}
					}
				}
			}
		}
	}
</style>
